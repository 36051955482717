// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[2]!./css/style.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
hr.horizon-sso {
  border: 0px;
  height: 1px;
  background: linear-gradient(to left, transparent, #2B286B, transparent);
}
div.list-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
a.site-list-button {
  width: 200px;
  border-radius: 5px;
  color: white;
  background-color: #2B286B;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 700;
}
a.site-list-button[disabled] {
  background-color: gray;
}
button.logout-button {
  width: 200px;
  background-color: white;
  border-color: #2B286B;
  border-radius: 5px;
  border-width: 1px;
  color: #2B286B;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 500;
}
button.logout-button:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;AACA;EACE,WAAW;EACX,WAAW;EACX,uEAAuE;AACzE;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;AACV;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,YAAY;EACZ,uBAAuB;EACvB,qBAAqB;EACrB,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB","sourcesContent":["@import '/src/css/style.css';\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\nhr.horizon-sso {\n  border: 0px;\n  height: 1px;\n  background: linear-gradient(to left, transparent, #2B286B, transparent);\n}\ndiv.list-box {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 4px;\n}\na.site-list-button {\n  width: 200px;\n  border-radius: 5px;\n  color: white;\n  background-color: #2B286B;\n  padding-top: 5px;\n  padding-bottom: 5px;\n  font-weight: 700;\n}\na.site-list-button[disabled] {\n  background-color: gray;\n}\nbutton.logout-button {\n  width: 200px;\n  background-color: white;\n  border-color: #2B286B;\n  border-radius: 5px;\n  border-width: 1px;\n  color: #2B286B;\n  padding-top: 5px;\n  padding-bottom: 5px;\n  font-weight: 500;\n}\nbutton.logout-button:hover {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
