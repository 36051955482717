import reactCookie, { Cookies } from "react-cookie";

const cookies = new Cookies();
const COOKIE_OPTIONS = {
  path:'/',
  domain: '.ella.so',
}
const VALUES = [
  'UA_ELLA_EMAIL', 'UA_ELLA_LOGIN', 'UA_ELLA_USERNAME', 'UA_ELLA_HD', 'ROLE_CHECK'
]
// 쿠키등록
// 1주일
let maxAge: number = 60 * 60 * 24 * 7;
const selectNames = (name:string) => {
  if (name === VALUES[0]) {
    return 'EMAIL'
  } else if (name === VALUES[1]) {
    return 'LOGIN'
  } else if (name === VALUES[2]) {
    return 'USERNAME'
  } else if (name === VALUES[3]) {
    return 'HD'
  } else if (name === VALUES[4]) {
    return 'ROLE_CHECK'
  } else {
    return ''
  }
}
export const setCookie = (name: string, value: string ) => {
  const othersName = selectNames(name);
  return cookies.set(name, value, {
    ...COOKIE_OPTIONS,
    sameSite: 'none',
    secure: true,
    maxAge: maxAge
  });
};

export const getCookie = (name: string) => {
  const getCookiesName = cookies.get(name);
  return getCookiesName;
};

export const removeCookie = (name: string, option?: any) => {
  return cookies.remove(name, { ...option });
};
export const removeAllCookies = async () => {
  const values = [
    'UA_ELLA_EMAIL', 'UA_ELLA_LOGIN', 'UA_ELLA_USERNAME', 'UA_ELLA_HD', 'ROLE_CHECK',
    'EMAIL', 'LOGIN', 'USERNAME', 'CHECK_HD',
  ]
  for await (const cookieName of values) {
    cookies.remove(cookieName, COOKIE_OPTIONS);
  }

  return true;
}

export const checkAllCookies = () => {
  
  const hasCookieNames = [
    'UA_ELLA_LOGIN','UA_ELLA_USERNAME','UA_ELLA_EMAIL','ROLE_CHECK'
  ]
  for (let i = 0; i < hasCookieNames.length; i++) {
    const target = getCookie(hasCookieNames[i]);
    // console.log('target[',i,'] =', target)
    if (!target) {
      return false;
    }
    if (i === 0 && Boolean(target) !== true) {
      // console.log('login check is :',target)
      return false;
    }
  };
  return true;
}