import {create} from 'zustand';
interface ILoginStore {
  email: string;
  userName:string;
  login: boolean;
  hd: string;
  admin: string;
  setUserInfo: (email:string, userName:string, login:boolean, hd?:string, admin?:string) => void;
}
const useLoginStore = create<ILoginStore>((set) => ({
  email: '',
  userName:'',
  login: false,
  hd: '',
  admin: '',
  setUserInfo: (email:string, userName:string, login:boolean, hd?:string, admin?:string) => {
    set(()=>({ email, userName, login, hd, admin}))
  }
}))
export default useLoginStore;