import React from 'react';
import './App.css';
import mainTitle from './images/United Associates BI Logo (Small) (Transparent).png';
import GoogleLoginButton from './googleLoginButton/GoogleLoginButton';
import { googleLogout } from '@react-oauth/google';
import { checkAllCookies, removeAllCookies } from './commons/Cookie';


function App() {
  const [isLogin, setIsLogin] = React.useState(false);
  
  const onClickLogout = async () => {
    const isRemoved = await removeAllCookies();
    if (isRemoved) {
      googleLogout();
      setIsLogin(false)
    }
    
  }
  React.useEffect(()=>{
    const hasCookies = checkAllCookies();
    if (hasCookies) {
      if (String(hasCookies) ==='true') {
        setIsLogin(true)
      } else setIsLogin(false)
    }
  })

  return (
    <div className="App">
      <div id="wrapper">
        <div id="content">
          <div id="main">
            <img src={mainTitle} style={{'alignContent':'center'}} /><br />
            <h1 className='welcome-text'>Welcome to United Associates</h1><br />
            <p className='sub-text'>Machine Learning Server - ML001</p>
            <hr className='horizon-sso'/>
            {!isLogin && 
              <GoogleLoginButton />
            }
            {isLogin &&
              <div className='list-box'>
                <a className='site-list-button' href="https://demo.ella.so/">Demo</a>
                <a className='site-list-button' href="https://manage.ella.so/">Management</a>
                <a className='site-list-button' href="https://finetune.ella.so/">Finetune</a>
                <a className='site-list-button' href="https://admin.ella.so/">Admin - B2C[test]</a>
                <button className='logout-button' onClick={async()=>await onClickLogout()}>Logout</button>
              </div>
            }
            <hr className='horizon-sso'/>
            <p><b>United Associates inc</b></p>
          </div>
          <div id="navigation">
            <a href="https://mail.una.co.kr" title="Mail">mail</a> |
            <a href="/webcal" title="Calendar">Calendar</a> |
            <a href="/changepassword" title="Change Password">Change Password</a> |
            <a href="/profilemanager" title="Profile Manager">Profile Manager</a>
          </div>
          <div id="footer">
            <a href="http://www.apple.com/server/">Copyright &copy; 2024 United Associates Inc. All rights reserved.</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
