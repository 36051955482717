const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID ? process.env.REACT_APP_GOOGLE_CLIENT_ID : '';
const googleUserInfoAPI = process.env.REACT_APP_USERINFO_API_URL ? process.env.REACT_APP_USERINFO_API_URL:'';
const HOST_BACKEND= process.env.REACT_APP_BACK_HOST ? process.env.REACT_APP_BACK_HOST:'';

const loginAPI = process.env.REACT_APP_LOGIN_API_URL ? HOST_BACKEND+process.env.REACT_APP_LOGIN_API_URL:'';
const userCheck = process.env.REACT_APP_LOGIN_HS_USER_CHECK_URL ? HOST_BACKEND+process.env.REACT_APP_LOGIN_HS_USER_CHECK_URL:'';


const CONFIG = {
  googleClientId,
  googleUserInfoAPI,
  loginAPI,
  userCheck
}

export default CONFIG;